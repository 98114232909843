<script setup lang="ts"></script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 15.8889C12.2939 15.8889 12.5278 16.1189 12.5478 16.4072C14.5606 16.1578 16.1578 14.5606 16.4072 12.5478C16.1183 12.5278 15.8889 12.2933 15.8889 12C15.8889 11.7067 16.1183 11.4722 16.4067 11.4517C16.1578 9.43944 14.56 7.84167 12.5478 7.59278C12.5278 7.88111 12.2939 8.11111 12 8.11111C11.7061 8.11111 11.4722 7.88111 11.4517 7.59333C9.43944 7.84222 7.84167 9.44 7.59278 11.4522C7.88167 11.4722 8.11111 11.7067 8.11111 12C8.11111 12.2933 7.88167 12.5278 7.59333 12.5483C7.84222 14.5606 9.44 16.1583 11.4522 16.4072C11.4722 16.1189 11.7061 15.8889 12 15.8889ZM12 14.7778C13.5341 14.7778 14.7778 13.5341 14.7778 12C14.7778 10.4659 13.5341 9.22222 12 9.22222C10.4659 9.22222 9.22222 10.4659 9.22222 12C9.22222 13.5341 10.4659 14.7778 12 14.7778Z"
            fill="url(#paint0_linear_601_7976)"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 6.66667C0 2.98477 2.98477 0 6.66667 0H17.3333C21.0152 0 24 2.98477 24 6.66667V17.3333C24 21.0152 21.0152 24 17.3333 24H6.66667C2.98477 24 0 21.0152 0 17.3333V6.66667ZM17.5272 11.4444H18.1111C18.4183 11.4444 18.6667 11.6933 18.6667 12C18.6667 12.3067 18.4183 12.5556 18.1111 12.5556H17.5272C17.2661 15.1761 15.1761 17.2656 12.5556 17.5272V18.1111C12.5556 18.4178 12.3072 18.6667 12 18.6667C11.6928 18.6667 11.4444 18.4178 11.4444 18.1111V17.5272C8.82389 17.2661 6.73444 15.1761 6.47278 12.5556H5.88889C5.58167 12.5556 5.33333 12.3067 5.33333 12C5.33333 11.6933 5.58167 11.4444 5.88889 11.4444H6.47278C6.73389 8.82389 8.82389 6.73389 11.4444 6.47278V5.88889C11.4444 5.58222 11.6928 5.33333 12 5.33333C12.3072 5.33333 12.5556 5.58222 12.5556 5.88889V6.47278C15.1761 6.73389 17.2661 8.82389 17.5272 11.4444Z"
            fill="url(#paint1_linear_601_7976)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_601_7976"
                x1="12"
                y1="0"
                x2="12"
                y2="24"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_601_7976"
                x1="12"
                y1="0"
                x2="12"
                y2="24"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
        </defs>
    </svg>
</template>
